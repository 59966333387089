import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useSpring, animated } from 'react-spring';

import { heights } from '#helpers/scroll-to';
import { useWindowSize } from '#hooks/index';

import styles from './index.module.scss';

const Button = dynamic(() => import('#app/UI/molecules/new-button'));
const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const isInteger = num => num % 1 == 0;

const Number = ({ num }) => {
  const { number } = useSpring({
    from: { number: num / 2 },
    number: num,
    delay: 400,
    config: { mass: 1, tension: 50, frinction: 10 }
  });

  if (isInteger(num)) {
    return <animated.span>{number.to(num => num.toFixed(0))}</animated.span>;
  }

  return (
    <animated.span>{number.to(num => num.toFixed(num % 1 === 0 ? 0 : 1))}</animated.span>
  );
};

const SocialItem = ({
  title,
  description,
  isPlus = false,
  isPlusRight = true,
  animation = false,
  width = 'auto'
}) => {
  return (
    <li className={cx(styles.social_item)} style={{ minWidth: width }}>
      <Typography className={cx(styles.socialQty, 'sm_text-center')} tag="h3">
        {isPlus && !isPlusRight && '+'}
        {animation ? <Number num={title} /> : title}
        {isPlus && isPlusRight && '+'}
      </Typography>
      <Typography className={cx(styles.socialDesc, 'sm_text-left')} tag="body-small">
        {description}
      </Typography>
    </li>
  );
};

const Hero = () => {
  const { isDesktop } = useWindowSize();

  return (
    <section className={cx(styles.wrapper)}>
      <div className={cx(styles.container, 'pt-80 pb-32 sm_pt-30 sm_pb-0')}>
        {/* Text */}
        <div className={cx(styles.text)}>
          <Typography className={cx(styles.title)} tag="h1">
            Educar para innovar:{' '}
            <span className={styles.aqua}>
              preparamos a los líderes digitales del futuro
            </span>
          </Typography>

          <div className={cx(styles.sectionButtons)}>
            <Button
              type="scroll"
              variant="orange"
              scrollOptions={{
                to: 'programs-for-organizations',
                offset: {
                  mobile: {
                    hidden: -heights.mobile.header,
                    visible: -120
                  },
                  desktop: {
                    hidden: -heights.desktop.header,
                    visible: -100
                  }
                }
              }}
              height={isDesktop ? '54px' : '48px'}
              width={isDesktop ? '300px' : '212px'}
              textOptions={{ fontSize: [20, 18] }}
            >
              Contáctanos
            </Button>
          </div>

          <ul className={cx(styles.list, 'flex gap-x-64 sm_gap-x-48 sm_gap-y-36')}>
            <SocialItem
              title={18000}
              description={<>Estudiantes {isDesktop && <br />} graduados</>}
              width="124px"
              animation
              isPlus
              isPlusRight={false}
            />
            <SocialItem
              title={50}
              description={
                <>
                  Organizaciones
                  {isDesktop && <br />} confían en nosotros
                </>
              }
              width="138px"
              animation
              isPlus
              isPlusRight={false}
            />

            <SocialItem
              title={22}
              description={<>Países en {isDesktop && <br />} nuestra comunidad</>}
              width="132px"
              isPlus
              isPlusRight={false}
            />
            <SocialItem
              title={'100%'}
              description={<>En vivo y con {isDesktop && <br />} profesores reales</>}
              width="122px"
            />
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Hero;
